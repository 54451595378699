export const latestChangelog = [
  {
    "date": "May 10, 2024",
    "href": "https://the-guild.dev/graphql/hive/product-updates/2024-05-10-openid-connect-logs",
    "title": "Live Debugging of OpenID Connect Logs",
    "description": "Organization admins can now live debug OpenID Connect logs in the Hive Dashboard."
  },
  {
    "date": "April 23, 2024",
    "href": "https://the-guild.dev/graphql/hive/product-updates/2024-04-23-graphql-server-integrations-workers-support",
    "title": "Specialized packages for GraphQL Servers & Cloudflare Worker Support",
    "description": "Enhanced integration with GraphQL servers, deprecating `@graphql-hive/client` and adding support for Cloudflare Workers."
  },
  {
    "date": "April 9, 2024",
    "href": "https://the-guild.dev/graphql/hive/product-updates/2024-04-09-approval-comment",
    "title": "Adding Notes to Schema Check Approvals",
    "description": "Give your team more context when approving schema changes by leaving a note."
  },
  {
    "date": "April 9, 2024",
    "href": "https://the-guild.dev/graphql/hive/product-updates/2024-04-09-deprecated-schema-view",
    "title": "Introducing Deprecated Schema View",
    "description": "Find and understand the deprecated part of your GraphQL schema"
  }
];
